var exports = {};
exports = inquire;
/**
 * Requires a module only if available.
 * @memberof util
 * @param {string} moduleName Module to require
 * @returns {?Object} Required module if available and not empty, otherwise `null`
 */

function inquire(moduleName) {
  try {
    var mod = eval("quire".replace(/^/, "re"))(moduleName); // eslint-disable-line no-eval

    if (mod && (mod.length || Object.keys(mod).length)) return mod;
  } catch (e) {} // eslint-disable-line no-empty


  return null;
}

export default exports;